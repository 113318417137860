import React, { useEffect, useState } from 'react';
import tw, { css } from 'twin.macro';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { validateEmail } from '../utils';
import { contentStyles } from './index';
import { SignUpForNews, PaperPlane, NewsletterSlimTail } from './Decorations';
import TiltButton from './TiltButton';

const Section = tw.section`relative w-full flex flex-col justify-between items-start py-12 px-5 md:(px-8 py-24) lg:px-20 bg-blue-dark text-white`;
const Inner = tw.div`w-full mx-auto 2xl:max-w-6xl 3xl:max-w-7xl`;

export const SectionTitle = tw.p`font-bold m-0 leading-tight text-xl sm:text-4xl`;
export const SectionHeader = tw.div`flex justify-between items-center w-full mb-10 flex-col items-start sm:(pl-0 flex-row items-center)`;

const Inputs = tw.div`w-full sm:(w-2/3) flex flex-wrap`;
const ConsentContainer = tw.div`w-full sm:(w-1/3)`;
const labelStyles = css`
  ${tw`relative block h-12 mb-6 font-semibold w-full sm:(w-1/2 pr-10 mb-10)`}
  span {
    ${tw`absolute block z-10 text-white text-base top-3 pointer-events-none origin-left transition-transform duration-300`}
  }
  input {
    ${tw`absolute block bg-transparent border-0 border-b font-semibold h-full w-full sm:w-10/12 focus:outline-none`}
  }
`;
const completedInputStyles = css`
  span {
    transform: translateY(-30px) scale(0.9);
    ${tw`text-white-50`}
  }
  input {
    ${tw`border-white-50`}
  }
`;
const inFocusStyles = css`
  span {
    transform: translateY(-30px) scale(0.9);
    ${tw`text-green`}
  }
  input {
    ${tw`border-green`}
  }
`;

const checkboxStyles = tw`outline-none relative inline-block text-transparent cursor-pointer border border-white border-solid h-6 w-6 mt-1 focus:border-green hover:border-green`;
const checkedStyles = css`
  &:after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #5ec2bb;
    top: 3px;
    left: 3px;
  }
`;

const NewsletterSlim = ({ signUpFrom }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [email, setEmail] = useState('');
  const [fieldsValid, setFieldsValid] = useState();
  const [iConsent, setIConsent] = useState(false);
  const [formStatus, setFormStatus] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    let counter = 0;
    if (firstName.length > 0) counter += 1;
    if (lastName.length > 0) counter += 1;
    if (jobTitle.length > 0) counter += 1;
    if (validateEmail(email)) counter += 1;
    if (iConsent) counter += 1;
    setFieldsValid(counter);
    setError(false);
  }, [firstName, lastName, jobTitle, email, iConsent]);

  const onSubmit = async () => {
    let fields = {
      FIRSTNAME: firstName,
      LASTNAME: lastName,
      JOBTITLE: jobTitle,
    };

    if (signUpFrom) fields = { ...fields, SIGNUPFROM: signUpFrom };

    const response = await addToMailchimp(email, fields);

    if (response.result === 'success') setFormStatus(response.result);
    if (response.result === 'error') setError(response);
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && formStatus === 'success') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'newsletterSubmissionCompleted',
      });
    }
  }, [formStatus]);

  return (
    <Section id="newsletter">
      <Inner>
        <SectionHeader tw="flex-row">
          <SectionTitle tw="w-full sm:w-3/5">Sign up for News</SectionTitle>
          <NewsletterSlimTail tw="hidden md:block" />
          <PaperPlane tw="sm:(mr-14)" />
        </SectionHeader>
        {formStatus === 'success' && (
          <div css={contentStyles} tw="mt-8">
            <h1 tw="text-xl text-green font-bold mb-4">Welcome! 🎉</h1>
            <p tw="mb-2">
              We look forward to having you on board & deliver relevant updates to your inbox! 📬
            </p>
          </div>
        )}
        {!formStatus && (
          <div tw="mt-12 flex items-end flex-col sm:flex-row">
            {error ? (
              <p
                dangerouslySetInnerHTML={{ __html: error.msg }}
                css={contentStyles}
                tw="mx-auto mt-8"
              />
            ) : (
              <>
                <Inputs>
                  <Input
                    id="mce-FNAME"
                    value={firstName}
                    label="First Name"
                    onChange={setFirstName}
                  />
                  <Input
                    id="mce-MMERGE2"
                    value={lastName}
                    label="Last Name"
                    onChange={setLastName}
                  />
                  <Input
                    id="mce-MMERGE3"
                    value={jobTitle}
                    label="Job Title"
                    onChange={setJobTitle}
                    tw="sm:(mb-0)"
                  />
                  <Input
                    id="mce-EMAIL"
                    value={email}
                    label="Email"
                    onChange={setEmail}
                    tw="sm:(mb-0)"
                  />
                </Inputs>
                <ConsentContainer>
                  <label htmlFor="consent" tw="flex justify-between items-start mb-5">
                    <div
                      id="consent"
                      onClick={() => setIConsent((val) => !val)}
                      tw="w-1/5"
                      css={[checkboxStyles, iConsent && checkedStyles]}
                      role="button"
                      aria-label="consent"
                      tabIndex="0"
                      onKeyPress={() => setIConsent((val) => !val)}
                    />
                    <span tw="w-11/12 ml-5 text-xxs sm:(text-xxs ml-0)">
                      At Digital Hub Denmark we would like to use your name and e-mail so that we
                      can send you our newsletter. <br />
                      <br /> You can withdraw your consent at any time, either by clicking on
                      “Unsubscribe” at the bottom of a newsletter or by sending us an email at{' '}
                      <a href="https://digitalhubdenmark.dk/privacy-policy">
                        info@digitalhubdenmark.dk
                      </a>
                    </span>
                    {/* <a
                      href="https://digitalhubdenmark.dk/privacy-policy"
                      tw="underline font-semibold"
                    >
                      privacy policy
                    </a> */}
                  </label>
                  <TiltButton
                    label="Sign up"
                    onClick={() => onSubmit()}
                    disabled={fieldsValid !== 5}
                    tw="w-full"
                  />
                </ConsentContainer>
              </>
            )}
          </div>
        )}
      </Inner>
    </Section>
  );
};

const Input = ({ id, label, onChange, type, value, ...rest }) => {
  const [inFocus, setInFocus] = useState();
  const completed = value?.length > 0 && !inFocus;

  return (
    <label
      htmlFor="mce-MMERGE3"
      css={[labelStyles, inFocus && inFocusStyles, completed && completedInputStyles]}
      {...rest}
    >
      <span>{label}</span>
      <input
        id={id}
        name={id}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        type={type || 'text'}
        onFocus={() => setInFocus(true)}
        onBlur={() => setInFocus()}
        tw="text-white text-lg"
      />
    </label>
  );
};

export default NewsletterSlim;
